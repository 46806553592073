<template>
  <div class="rejected-actions-wrapper action-container">
    <v-row class="ma-0 mt-4">
      <v-icon>far fa-shredder</v-icon>
      <v-row class="ma-0 ml-3">
        <v-col cols="12" class="title pa-0">Afgewezen acties</v-col>
        <v-col cols="12" class="caption pa-0">Acties die je hebt afgewezen en/of erkende maatregelen die niet
          van
          toepassing zijn
        </v-col>
      </v-row>
    </v-row>
    <vuetify-resource
        :can-add="false"
        :can-delete="false"
        :can-update="false"
        :get-data-callback="getRejectedActions"
        :show-speed-dail="false"
        :table-content="getTableContent()"
        :texts="texts"
        ref="resource"
        :use-checkboxes="false"
        @row-click="openActionDialog"
        :rowsPerPage="20"
        :rowsPerPageItems="[10,20,50]"
    >
      <template slot="crudActionsAfter" slot-scope="slotProps">
        <v-tooltip left>
          <template #activator="{ on }">
            <v-btn
                @click.stop="revertAction(slotProps.resource.id)"
                color="accent"
                text
                icon
                v-on="on"
            >
              <v-icon>far fa-history</v-icon>
            </v-btn>
          </template>
          <span>Terugdraaien</span>
        </v-tooltip>
      </template>
    </vuetify-resource>
    <action-dialog :action-id="actionDialogId" ref="actionDialog"></action-dialog>
  </div>
</template>

<script>

import ActionDialog from './ActionDialog.vue';
import BaseActions from './BaseActions.vue';

export default {
  extends: BaseActions,
  components: { ActionDialog },
  watch: {},
  props: [],
  name: 'rejected-actions',
  data() {
    return {
      isSearching: false,
      texts: {
        'no-data': 'Er zijn geen afgewezen acties. U kunt zelf open acties afwijzen.',
        'no-results': 'Er zijn geen afgewezen acties. U kunt zelf open acties afwijzen.',
      },
    };
  },
  created() {

  },
  methods: {
    getRejectedActions(pagination, search) {
      this.isSearching = search !== '';
      const { sortBy, sortDesc, page, itemsPerPage } = pagination;
      let meters = this.meterIds ? this.meterIds : {};
      return new Promise((resolve) => {
        this.$http.get('me/organisation/current/meter-action/rejected', {
              params: {
                ...{
                  q: search,
                  sortBy: sortBy[0],
                  desc: sortDesc[0] ? 1 : 0,
                  page: page,
                  perPage: itemsPerPage,
                },
                ...meters,
              },
            })
            .then((response) => {
              let items = response.data.data.map(this.mapActions);
              let total = response.data.meta.total;
              resolve({
                items,
                total,
              });
            });
      });

    },
    revertAction(id) {
      this.$http.put('meter-action/' + id + '/status', {
        status: 1, // open

      }).then(() => {
        this.$emit('reload');
      });
    },
  },
};
</script>

<style>
.rejected-actions-wrapper th:last-child {
  width: 62px;
}
</style>
