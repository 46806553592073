<template>
  <v-dialog max-width="600px" v-model="dialog">
    <v-card v-if="action !== null">
      <v-card-title class="white-card">
        <span class="title">{{ action.action.name }}</span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col class="pr-4" cols="3">
            <v-tooltip right v-for="(file, index) in action.files" :key="index">
              <template #activator="{ on }">
                <v-img class="mb-3"
                       :lazy-src="file.public_path.replace('?', '/10?')"
                       :src="file.public_path.replace('?', '/120?')"
                       v-on="on"
                       lazy></v-img>
              </template>
              <span>
                                <v-img :lazy-src="file.public_path.replace('?', '/10?')"
                                       :src="file.public_path"
                                       width="400px"
                                       lazy></v-img>
                            </span>
            </v-tooltip>
          </v-col>
          <v-col cols="9" class="black--text">
            <v-row>
              <v-col cols="6" class="subheading font-weight-bold pa-0">Status:</v-col>
              <v-col cols="6" class="pa-0">{{ action.meter_action_status.name }}</v-col>
            </v-row>
            <v-row>
              <v-col cols="6" class="subheading font-weight-bold pa-0">Jaarlijkse besparing:</v-col>
              <v-col cols="6" class="pa-0">{{ this.generateMeterTypeForAction(action.saving, action) }}</v-col>
            </v-row>
            <v-row>
              <v-col cols="6" class="subheading font-weight-bold pa-0">Jaarlijkse besparing CO2:</v-col>
              <v-col cols="6" class="pa-0">{{ action.yearly_carbon_dioxide_saved | parseToNumber }} Kg CO2</v-col>
            </v-row>
            <v-row>
              <v-col cols="6" class="subheading font-weight-bold pa-0">Jaarlijkse kostenbesparing:</v-col>
              <v-col cols="6" class="pa-0">{{ action.yearly_saving | currency }}</v-col>
            </v-row>
            <v-row>
              <v-col cols="6" class="subheading font-weight-bold pa-0">Investering:</v-col>
              <v-col cols="6" class="pa-0">{{ action.investment | currency }}</v-col>
            </v-row>
            <v-row>
              <v-col cols="6" class="subheading font-weight-bold pa-0">Terugverdientijd:</v-col>
              <v-col cols="6" class="pa-0">{{ action.payback_time | year }}</v-col>
            </v-row>
            <v-row v-if="action.executed_at === null">
              <v-col cols="6" class="subheading font-weight-bold pa-0">Ingeplande datum:</v-col>
              <v-col cols="6" class="pa-0">
                <template v-if="action.scheduled_for !== null">{{ action.scheduled_for | validDate }}
                </template>
                <template v-else>niet bekend</template>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col cols="6" class="subheading font-weight-bold pa-0">Uitgevoerd op:</v-col>
              <v-col cols="6" class="pa-0">
                {{ action.executed_at | validDate }}
              </v-col>
            </v-row>
            <v-row class="ma-0">
              <v-textarea label="Notitie" v-model="action.note"></v-textarea>
            </v-row>
            <v-row class="ma-0">
              <v-col cols="12" class="subheading font-weight-bold pa-0">Uw situatie</v-col>
              <v-col cols="12" v-html="nlToBr(action.situational_description)" class="pa-0"></v-col>
            </v-row>
            <v-row class="ma-0">
              <v-col cols="12" class="subheading font-weight-bold pa-0 pt-3">Algemene beschrijving</v-col>
              <v-col cols="12" v-html="nlToBr(action.action.description)" class="pa-0 pt-3"></v-col>
            </v-row>
          </v-col>
        </v-row>

      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="closeDialog" color="blue darken-1" text>Sluiten</v-btn>
        <v-btn @click="saveNote" color="blue darken-1" text>Opslaan</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import meterTypes from '../../mixins/meterTypes.js';

export default {
  name: 'action-dialog',
  props: ['action-id'],
  mixins: [meterTypes],
  watch: {
    actionId: {
      handler(actionId) {
        this.action = null;
        this.getAction(actionId);
      },
    },
  },
  data() {
    return {
      action: null,
      dialog: false,
    };
  },
  methods: {
    closeDialog() {
      this.dialog = false;
    },
    getAction(id) {
      this.$http.get('meter-action/' + id).then((response) => {
        this.action = response.data.data;
      });
    },
    saveNote() {
      this.$http.put('meter-action/' + this.actionId + '/note', {
        note: this.action.note,
      }).then(() => {
        this.closeDialog();
      });
    },
    open() {
      this.dialog = true;
    },
    nlToBr(value) {
      if (value === null) {
        return value;
      }
      return value.replace(/\n/g, '<br />');
    },
  },
};
</script>

<style scoped>

</style>
