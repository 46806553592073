<template>
  <div class="page-actions">
    <open-actions ref="openActions"
                  :meter-ids="meterIds"
                  @reload="reload()"
                  @searching="searching" @selectionMeters="selectionMeters"></open-actions>
    <accepted-actions ref="acceptedActions" :meter-ids="meterIds" @reload="reload()"></accepted-actions>
    <rejected-actions ref="rejectedActions" :meter-ids="meterIds" @reload="reload()"></rejected-actions>
  </div>
</template>

<script>

import AcceptedActions from '../components/actions/AcceptedActions.vue';
import OpenActions from '../components/actions/OpenActions.vue';
import RejectedActions from '../components/actions/RejectedActions.vue';

export default {
  name: 'actions',
  components: {
    RejectedActions,
    AcceptedActions,
    OpenActions,
  },
  data() {
    return {
      meterIds: {},
    };
  },
  methods: {
    reload() {
      this.$refs.openActions.$refs.resource.getDataHandler();
      this.$refs.acceptedActions.$refs.resource.getDataHandler();
      this.$refs.rejectedActions.$refs.resource.getDataHandler();
      this.$refs.openActions.resetOpenStatistics();
    },
    searching(search) {
      this.$refs.openActions.$refs.resource.search = search;
      this.$refs.acceptedActions.$refs.resource.search = search;
      this.$refs.rejectedActions.$refs.resource.search = search;
    },
    async selectionMeters(meterIds) {
      this.meterIds = meterIds;
      await this.$nextTick();
      this.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.page-actions {
  width: 100%;
}
</style>
