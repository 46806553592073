<template>
  <v-autocomplete
      :items="meters"
      @change="selected"
      clearable
      item-avatar="fa-bars"
      item-text="name"
      item-value="id"
      :label="meterSelectorLabel"
      multiple
      v-model="meterSelectionId"
  >
    <template #selection="{ item, index }">
      <div class="float-left" v-if="index === 0">
        <v-avatar size="25px">
          <v-icon color="#EAA002" v-if="item.meter_type_id === 1">far fa-burn</v-icon>
          <v-icon color="primary" v-else-if="item.meter_type_id === 2">far fa-bolt</v-icon>
        </v-avatar>
        {{ item.name }}
      </div>
      <div class="grey--text caption ml-3" v-if="index === 1">(+ {{ meterSelectionId.length - 1 }} andere)</div>
    </template>
    <template #item="{item}">
      <v-icon v-if="!meterSelectionId.includes(item.id)">far fa-square</v-icon>
      <v-icon v-else>fas fa-check-square</v-icon>
      <v-list-item-avatar>
        <v-icon color="gray" v-if="item.meter_type_id < 1">far fa-times-circle</v-icon>
        <v-icon color="#EAA002" v-else-if="item.meter_type_id === 1">far fa-burn</v-icon>
        <v-icon color="primary" v-else-if="item.meter_type_id === 2">far fa-bolt</v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title v-html="item.name"></v-list-item-title>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
import MeterActionDisplay from '../mixins/MeterActionDisplay.js';
import user from '../mixins/user.js';

export default {
  name: 'MeterSelector',
  mixins: [user, MeterActionDisplay],
  data() {
    return {
      meters: [],
      meterSelectionId: [],
    };
  },
  props: {
    disableNullOption: {
      type: Boolean,
      default: false,
    },
    meterSelectorLabel: {
      type: String,
      default: 'Meters',
    },
  },
  created() {
    this.getMeters().then((meters) => {
      if (!this.disableNullOption) {
        meters.unshift({
          id: '',
          name: 'Geen',
          meter_type_id: 0,
          label: 'Geen',
        });
      }
      this.meters = meters;
    });
  },
  computed: {
    filterSelectionMeters() {
      let selectionId = this.meterSelectionId;

      return this.meters.reduce(function (arr, current) {
        if (selectionId.indexOf(current.id) !== -1) {
          arr.push(current);
        }
        return arr;
      }, []);
    },
  },
  methods: {
    getMeters() {
      return new Promise((resolve) => {
        this.getUserValue('current_organisation_id').then((currentOrganisationId) => {
          this.$http.get('organisation/' + currentOrganisationId + '/meter?perPage=10000').then((response) => {
            resolve(response.data.data.map((meter) => {
              return {
                id: meter.id,
                name: this.meterColumn(meter.ean, meter.location),
                meter_type_id: meter.meter_type.id,
                label: this.meterColumn(meter.ean, meter.location),
              };
            }));
          });
        });
      });
    },
    selected() {
      this.$emit('input', this.filterSelectionMeters);
    },
  },
};
</script>

<style scoped>

</style>
