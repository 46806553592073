export default {
  methods: {
    generateMeterTypeForAction: function (value, action) {
      if (value !== null && typeof value !== 'undefined' && action?.meter?.meter_type?.id) {
        return this.$options.filters.parseToNumber(value) + ' ' + (action.meter.meter_type.id === 1 ? 'm³' : 'kWh');
      }

      return value ? this.$options.filters.parseToNumber(value) : value;
    },
  },
};
