<script>
import MeterActionDisplay from './../../mixins/MeterActionDisplay.js';
import meterType from './../../mixins/meterTypes.js';

export default {
  name: 'BaseActions',
  mixins: [meterType, MeterActionDisplay],
  props: {
    meterIds: {
      type: Object,
    }
  },
  data() {
    return {
      actionDialogId: null,
      actionDialog: false,
      content: [
        {
          text: 'Naam',
          align: 'left',
          sortable: true,
          value: 'name',
          width: 250,
        },
        {
          text: 'Jaarlijkse besparing',
          align: 'left',
          sortable: true,
          value: 'saving',
          width: 120,
        },
        {
          text: 'Jaarlijkse kostenbesparing',
          align: 'left',
          sortable: true,
          value: 'yearly_saving',
          width: 100,
        },
        {
          text: 'Investering',
          align: 'left',
          sortable: true,
          value: 'investment',
          width: 90,
        },
        {
          text: 'Terugverdientijd',
          align: 'left',
          sortable: true,
          value: 'payback_time',
          width: 115,
        },
        {
          text: 'Erkende maatregel',
          align: 'left',
          sortable: true,
          value: 'recognition_type',
          width: 110,
          tooltip: 'recognition_type_description',
        },
        {
          text: 'Meter',
          align: 'left',
          sortable: true,
          value: 'location',
          width: 150,
        },
      ],
    };
  },
  methods: {
    mapActions(meterAction) {
      return {
        id: meterAction.id,
        name: meterAction.action.name,
        saving: this.generateMeterTypeForAction(meterAction.saving, meterAction),
        scheduled_for: this.$options.filters.validDate(meterAction.scheduled_for),
        scheduled_for_value: meterAction.scheduled_for,
        executed_at: this.$options.filters.validDate(meterAction.executed_at),
        investment: this.$options.filters.currency(meterAction.investment),
        payback_time: this.$options.filters.year(meterAction.payback_time),
        recognition_type: meterAction.recognition_type !== null ? meterAction.recognition_type.name : '',
        recognition_type_description: meterAction.recognition_type !== null ? meterAction.recognition_type.description : '',
        location: this.meterColumn(meterAction.meter?.ean, meterAction.meter?.location),
        yearly_saving: this.$options.filters.currency(meterAction.yearly_saving),
      };

    },
    openActionDialog(actionId) {
      this.$refs.actionDialog.open();
      this.actionDialogId = actionId;
    },
    getTableContent() {
      return this.content;
    },
  },
};
</script>

<style>
table tr {
  cursor: pointer;
}

table.v-table thead th {
  white-space: unset;
}

.actionWrapper table th, .actionWrapper table td {
  padding: 0 7px !important;
}
</style>
