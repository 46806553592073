<template>
  <div class="open-actions-wrapper action-container">
    <v-row v-if="statistics !== null" class="ma-0 mt-3">
      <v-col :class="{'pb-3': $vuetify.breakpoint.xsOnly, 'pr-3': $vuetify.breakpoint.smAndUp}"
             class="pa-0"
             cols="12"
             sm="4">
        <v-card class="white-card">
          <v-card-title class="title overflow-with-eclipse">Totale investering</v-card-title>
          <v-card-text class="subheading pa-3 black--text">{{
              statistics.total_required_investment |
                  currency
            }}
          </v-card-text>
        </v-card>
      </v-col>
      <v-col :class="{'pb-3': $vuetify.breakpoint.xsOnly, 'pr-3': $vuetify.breakpoint.smAndUp}"
             class="pa-0"
             cols="12"
             sm="4">
        <v-card class="white-card">
          <v-card-title class="title overflow-with-eclipse">Potentiële totale jaarlijkse besparing
          </v-card-title>
          <v-card-text class="subheading pa-3 black--text">{{ statistics.potential_yearly_saving | currency }}
          </v-card-text>
        </v-card>
      </v-col>
      <v-col class="pa-0" cols="12" sm="4">
        <v-card class="white-card">
          <v-card-title class="title overflow-with-eclipse">Totale terugverdientijd</v-card-title>
          <v-card-text class="subheading pa-3 black--text">{{ statistics.payback_time | year }}</v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="ma-0 mt-2" justify="end">
      <v-col :class="{'mr-2': $vuetify.breakpoint.smAndUp}" cols="12" sm="4">
        <MeterSelector @input="handleMeterSelection" meter-selector-label="Filter op meters"/>
      </v-col>
      <v-col cols="12" sm="3">
        <v-text-field
            v-model="searchbar"
            append-icon="fa-search"
            hide-details
            label="Filter op naam"
            single-line
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="ma-0 mt-4">
      <v-icon>far fa-clipboard-list</v-icon>
      <v-row class="ma-0 ml-3">
        <v-col class="title pa-0" cols="12">Openstaande acties</v-col>
        <br>
        <v-col class="caption pa-0" cols="12">Acties die je nog uit kunt voeren</v-col>
      </v-row>
    </v-row>

    <vuetify-resource
        ref="resource"
        :can-add="false"
        :can-delete="false"
        :can-update="false"
        :get-data-callback="getOpenActions"
        :rowsPerPage="50"
        :rowsPerPageItems="[10,20,50]"
        :show-speed-dail="false"
        :table-content="getTableContent()"
        :texts="texts"
        :use-checkboxes="false"
        @row-click="openActionDialog"
    >
      <template slot="crudActionsAfter" slot-scope="slotProps">
        <v-tooltip left>
          <template #activator="{ on }">
            <v-btn
                v-on="on"
                color="accent"
                icon
                text
                @click.stop="rejectAction(slotProps.resource.id)"
            >
              <v-icon>far fa-shredder</v-icon>
            </v-btn>
          </template>
          <span>Afwijzen</span>
        </v-tooltip>
        <v-tooltip left>
          <template #activator="{ on }">
            <v-btn
                v-on="on"
                color="pink"
                icon
                text
                @click.stop="openScheduleAction(slotProps.resource)"
            >
              <v-icon>far fa-calendar-plus</v-icon>
            </v-btn>
          </template>
          <span>Inplannen</span>
        </v-tooltip>
        <v-tooltip left>
          <template #activator="{ on }">
            <v-btn
                v-on="on"
                color="blue"
                icon
                text
                @click.stop="openExecuteDialog(slotProps.resource)"
            >
              <v-icon>far fa-check</v-icon>
            </v-btn>
          </template>
          <span>Uitvoeren</span>
        </v-tooltip>
      </template>
    </vuetify-resource>
    <action-dialog ref="actionDialog" :action-id="actionDialogId"></action-dialog>
    <v-dialog v-model="scheduleDialog" max-width="320">
      <v-card>
        <v-card-title class="title">Inplannen</v-card-title>
        <v-card-text>
          <v-date-picker v-model="scheduleDialogDate" locale="nl-nl"></v-date-picker>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="scheduleDialog = false">Annuleren</v-btn>
          <v-btn color="primary" text @click="scheduleAction()">Inplannen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="executeDialog" max-width="320">
      <v-card>
        <v-card-title class="headline">Uitgevoerd op</v-card-title>
        <v-card-text>
          <v-date-picker v-model="executeDialogDate" locale="nl-nl"></v-date-picker>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="executeDialog = false">Annuleren</v-btn>
          <v-btn color="primary" text @click="executeAction">Uitvoeren</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import MeterSelector from '../MeterSelector.vue';
import ActionDialog from './ActionDialog.vue';
import BaseActions from './BaseActions.vue';

export default {
  name: 'open-actions',
  extends: BaseActions,
  components: {
    ActionDialog,
    MeterSelector,
  },
  watch: {
    searchbar() {
      this.$emit('searching', this.searchbar);
    },
  },
  props: [],
  data() {
    return {
      scheduleDialog: false,
      executeDialog: false,
      scheduleActionId: null,
      statistics: null,
      scheduleDialogDate: null,
      executeDialogDate: null,
      searchbar: '',
      selected: null,
      texts: {
        'no-data': 'Er zijn geen open acties',
        'no-results': 'Er zijn geen open acties',
      },
    };
  },
  created() {
    this.resetOpenStatistics();
  },
  methods: {
    getOpenActions(pagination, search) {
      const { sortBy, sortDesc, page, itemsPerPage } = pagination;
      let meters = this.meterIds ? this.meterIds : {};
      return new Promise((resolve) => {
        this.$http.get('me/organisation/current/meter-action/open', {
              params: {
                ...{
                  q: search,
                  sortBy: sortBy[0],
                  desc: sortDesc[0] ? 1 : 0,
                  page: page,
                  perPage: itemsPerPage,
                },
                ...meters,
              },
            })
            .then((response) => {
              let items = response.data.data.map(this.mapActions);
              let total = response.data.meta.total;
              resolve({
                items,
                total,
              });
            });
      });

    },
    resetOpenStatistics() {
      this.getOpenStatistics().then((statistics) => {
        this.statistics = statistics;
      });
    },
    getOpenStatistics() {
      return new Promise((resolve) => {
        this.$http.get('me/organisation/current/meter-action/statistic/open', {
          params: {
            ...this.meterIds,
          },
        }).then((response) => {
          resolve(response.data.data);
        });
      });
    },
    rejectAction(id) {
      this.$http.put('meter-action/' + id + '/status', {
        status: 3, // rejected
      }).then(() => {
        this.$emit('reload');
      });
    },
    openScheduleAction(meterAction) {
      this.scheduleActionId = meterAction.id;
      this.scheduleDialog = true;
      this.scheduleDialogDate = meterAction.scheduled_for_value;
    },
    scheduleAction() {
      this.$http.put('meter-action/' + this.scheduleActionId + '/schedule', {
        date: this.scheduleDialogDate,
      }).then(() => {
        this.scheduleDialog = false;
        this.$refs.resource.getDataHandler();
      });
    },
    executeAction() {
      this.$http.put('meter-action/' + this.selected.id + '/status', {
        status: 2, // accepted
        date: this.executeDialogDate,
      }).then(() => {
        this.executeDialog = false;
        this.$emit('reload');
      });
    },
    openExecuteDialog(selected) {
      this.selected = selected;
      this.executeDialog = true;
      this.executeDialogDate = selected.scheduled_for_value;
    },
    getTableContent() {
      let result = JSON.parse(JSON.stringify(this.content));

      result.push({
        text: 'Datum:',
        align: 'left',
        sortable: true,
        value: 'scheduled_for',
        width: 100,
      });

      return result;
    },
    handleMeterSelection(meters) {
      let obj = {};
      meters.forEach((meter, index) => {
        obj[`meters[${index}]`] = meter.id;
      });
      this.$emit('selectionMeters', obj);
    },
  },
};
</script>

<style>
.open-actions-wrapper th:last-child {
  width: 134px;
}
</style>
